@font-face {
    font-family: 'Inter-Regular';
    src: local('Inter-Regular'), url(./assets/fonts/Inter-Regular.ttf) format('truetype');
    font-weight: 400;
}
@font-face {
    font-family: 'Inter-ExtraLight';
    src: local('Inter-ExtraLight'), url(./assets/fonts/Inter-ExtraLight.ttf) format('truetype');
    font-weight: 200;
}
@font-face {
    font-family: 'Inter-SemiBold';
    src: local('Inter-SemiBold'), url(./assets/fonts/Inter-SemiBold.ttf) format('truetype');
    font-weight: 600;
}
@font-face {
    font-family: 'Inter-Bold';
    src: local('Inter-Bold'), url(./assets/fonts/Inter-Bold.ttf) format('truetype');
    font-weight: 700;
}
